import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    menuLangMargin: {},
    menuLangsWrapper: media(width, height, 'w', {
        768: {
            position: 'relative',
            'z-index': '99'
        },
        0: { display: 'none' }
    }),
    positionRelative: { position: 'relative' },
    regionsLangButton: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        position: 'relative',
        border: `1px solid ${variable.greyDeepAltExtraLightColor}`,
        'border-radius': '12px',
        color: variable.darkColor,
        transition: '.3s'
    },
    regionsLangButtonLight: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        position: 'relative',
        border: `1px solid ${variable.whiteLessTransparentColor}`,
        'border-radius': '12px',
        color: variable.darkColor,
        transition: '.3s'
    },
    loaderWrapper: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: 'rgba(0,0,0 ,0.5)',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'z-index': '9999'
    },
    menuLangsBackdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'transparent',
        'z-index': '-2'

    },
    menuLangSwitcherDesktop: {
        width: '24px',
        'min-width': '24px',
        height: '24px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'cursor': 'pointer',
        margin: '12px'
    },
    menuLangSwitcherDesktopLight: {
        width: '48px',
        'min-width': '48px',
        height: '48px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'cursor': 'pointer'
    },
    menuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        overflow: 'hidden'
    },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherFlag: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        height: '100%',
        width: '100%'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherNameDesktop: {
        'text-transform': 'capitalize',
        ...uikit.small
    },
    modalTitle: {
        color: variable.darkColor,
        ...uikit.title,
        'text-align': 'center',
        'font-weight': '500',
        'max-width': '376px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'margin-bottom': '32px'
    },
    modalTopDecor: {
        width: '32px',
        height: '4px',
        background: variable.greyExtraLightColor,
        'border-radius': '100px',
        margin: '0 0 20px'
    },
    FormWeb: media(width, height, 'w', {
        768: { width: '100%' },
        0: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': 1
        }
    }),
    formGroupWrapper: {
        display: 'flex',
        'flex-direction': 'column',
        'flex-wrap': 'nowrap',
        'justify-content': 'flex-start',
        'align-items': 'stretch',
        'margin-bottom': '16px',
        'max-width': '100%',
        width: '100%',
        flex: 'none'
    },
    modalClose: {
        position: 'absolute',
        top: '24px',
        right: '30px',
        'cursor': 'pointer'
    },
    modalCloseSvg: {
        fill: variable.greyExtraDarkColor,
        width: '12px',
        height: '12px'
    },
    modalWrapperMobile: media(width, height, 'w', {
        0: {
            width: '100%',
            height: '100%',
            display: 'flex',
            'justify-content': 'center',
            background: variable.blackTransparentColor,
            'flex-direction': 'row',
            'align-items': 'flex-end'
        }
    }),
    modalBody: media(width, height, 'w', {
        425: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 24px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        },
        0: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 32px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    modalWrapper: {
        'min-width': '576px',
        'max-width': '100%',
        background: variable.whiteColor,
        'border-radius': '20px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        position: 'relative',
        display: 'flex',
        'flex-direction': 'column',
        padding: '32px'
    },
    dropDownBtnsWrapper: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'margin-top': '12px'
    },
    dropDownText: { 'width': 'max-content', 'max-width': '264px' },

    dropDownWrapper: {
        filter: `drop-shadow(${variable.blackTransparentColor} 0px 10px 100px)`,
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center',
        position: 'absolute',
        'z-index': '100',
        top: 'calc(100% + 6px)',
        left: '50%',
        transform: 'translateX(-50%)',

        'font-style': 'normal',
        'font-weight': '500',
        ...uikit.small,
        'line-height': '1.14',
        color: variable.darkColor,
        'text-align': 'center'
    },
    dropDownWrapperLeft: {
        left: 'auto',
        right: '0',
        transform: 'none'
    },
    dropDown: {
        'background': variable.whiteColor,
        'border-radius': '14px',
        padding: '16px 30px',
        position: 'relative',
        top: '-5px'
    },
    triangleDropdown: {
        width: 0,
        height: 0,
        'border-left': '16.5px solid transparent',
        'border-right': '16.5px solid transparent',

        'border-bottom': `15px solid ${variable.whiteColor}`
    },
    wrapper: {
        padding: '12px',
        height: '48px',
        'min-width': '100px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'cursor': 'pointer',
        'border-left': 'none',
        'border-left': `1px solid ${variable.darkExtraTransparentColor}`,
        'font-style': 'normal',
        'font-weight': 'normal',
        ...uikit.small,
        'line-height': '1.14'
    },
    wrapperLight: {
        color: variable.whiteColor,
        'border-left': `1px solid ${variable.whiteLessTransparentColor}`
    },
    cancelButton: {
        ...uikit.small,
        border: `2px solid ${variable.darkExtraTransparentColor}`,
        background: 'transparent',
        'box-sizing': 'border-box',
        'border-radius': '12px',
        padding: '16px',
        'min-width': '73px',
        cursor: 'pointer'
    },
    submitButton: {
        ...uikit.small,
        border: `2px solid ${variable.orangeColor}`,
        background: variable.orangeColor,
        'border-radius': '12px',
        padding: '16px',
        'min-width': '73px',
        cursor: 'pointer',
        color: variable.whiteColor
    }
});
export default stylesheets;