import React, {
    useCallback, useContext, useMemo, useState
} from 'react';
import {
    Div, P, Portal, svgSprite
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/region-and-currency-switcher';
import Styles from '../../common/decorators/Styles';
import T from '../../common/components/T';
import { LangContext } from '../../common/decorators/Language';
import UserContext from '../../common/utils/getContext';
import { getFormData } from './initData';
import FormComponent from '../form/FormComponent';
import { getWidgetTranslates } from '../../common/utils/utils';
import { getGeoName } from '../../common/utils/memo';
import Skeleton from '../plug/Skeleton';

const RenderForm = ({
    response, setIsShowPortalModal, setIsShowConfirmModal, cancel, redirectToDomain, hideCountryAndCurrency
}) => {
    const { geoname, trans } = response;
    const {
        localization,
        lang,
        activeLangs,
        setState,
        currencies
    } = useContext(UserContext);


    const langsForMenu = useMemo(() => activeLangs?.filter((l) => l.active && l.show), [activeLangs])
    const activeLanguage = useMemo(() => activeLangs && activeLangs?.find((language) => language.alias === lang) || activeLangs?.find((language) => language.isDefault) || null, [activeLangs, lang])

    const [formData, setFormData] = useState({
        country: {
            value: geoname?.geoname_id,
            label: geoname?.name,
            code: geoname?.country
        },
        language: {
            value: activeLanguage?.alias,
            label: activeLanguage?.name
        }
    });
    // const [changedCountry, setChangedCountry] = useState(false)
    // const [domainShops, setDomainShops] = useState(false)

    // useEffect(async () => {
    //     setChangedCountry(true)
    //     if(changedCountry && !domainShops){

    //         setDomainShops(shopsData.data)
    //     }
    // }, [formData.country])


    const currencyList = useMemo(() => {
        if (currencies?.data.length) {
            const userCurrency = currencies.data.find(el => el.code === localization?.currency)
            const defaultCurrency = currencies.data.find(el => el.main === true)
            if (userCurrency || defaultCurrency){
                setFormData((prev) => ({
                    ...prev,
                    currency: userCurrency ? {
                        value: userCurrency.code,
                        label: userCurrency.code
                    } : {
                        value: defaultCurrency.code,
                        label: defaultCurrency.code
                    }
                }));
            }
            return currencies.data.map((item) => ({ id: item.code, title: item.code }));
        }
        return []
    }, [currencies?.data, localization?.currency]);

    const setFormDataMain = useCallback((key, val) => {
        if (key === 'country' && val) {
            let languageData = activeLangs?.find(el => el.alias === val.language) || activeLangs?.find(el => el.isDefault)
            let language = languageData ? { language: { value: languageData.alias, label: languageData.name } } : {}
            let currencyData = currencyList?.find(el => el.id === val.currency) || currencyList.find(el => el.main === true)
            let currency = currencyData ? { currency: { value: currencyData.id, label: currencyData.id } } : {}

            setFormData((pre) => ({
                ...pre,
                [key]: val,
                ...language,
                ...currency
            }))
        } else if (key === 'cdek_city' || key === 'cdek_region') {
            return
        } else {
            setFormData((pre) => ({
                ...pre,
                [key]: val
            }))
        }
    }, [activeLangs, currencyList]);


    const formDataFields = useMemo(() => getFormData(
        currencyList,
        langsForMenu,
        formData,
        trans,
        hideCountryAndCurrency
    ), [formData, trans, langsForMenu, currencyList, hideCountryAndCurrency]);

    const sender = async (formData, setAllowedSend, setFieldsErr) => {
        setFieldsErr({})
        if (Object.keys(formData).length && Object.keys(formData).every((key) => formData[key])) {
            let newLocalization = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: key === 'country' ? { ...formData[key], code: formData[key]?.code || formData[key]?.country } : formData[key].value }), {})
            if (!!newLocalization && typeof newLocalization === 'object'){
                // setState('localization', newLocalization);
                redirectToDomain(newLocalization)
            }
            if (lang !== newLocalization.language){
                setState('lang', newLocalization.language);
            }
            setIsShowPortalModal(false);
            setIsShowConfirmModal && setIsShowConfirmModal(false);
        } else if (Object.keys(formData).length){
            setAllowedSend(true)
            setFieldsErr(Object.keys(formData).reduce((acc, key) => {
                if (formData[key]) return acc;
                return { ...acc, [key]: [<T key='SiteSettings-app-setting-error' customTranslates={trans} textName='SiteSettings-app-setting-error' defaultStr='Поле обязательно для заполнения' page='widget' group='SiteSettings'/>] }
            }, {}))
        }
    };
    return (
        <FormComponent
            data={formDataFields}
            setFormDataMain={setFormDataMain}
            formDataMain={formData}
            sendData={{ sender }}
            buttons={[
                {
                    type: 'cancel',
                    clickFunc: () => cancel()
                },
                {
                    type: 'ok',
                    buttonText: <T textName='save' defaultStr='Сохранить' page='all'/>
                }
            ]}
            customData={{}}
        />
    )
}
const RegionAndCurrencySwitcher = ({
    styles, setIsShowConfirmModal, setIsShowPortalModal, mobile, redirectToDomain, hideCountryAndCurrency
}) => {
    const { translation } = useContext(LangContext);
    const { localization, lang } = useContext(UserContext);

    const [trans, setTranslates] = useState(null);
    const loadData = useCallback(async() => {
        const trans = await getWidgetTranslates(lang, 'SiteSettings', translation, setTranslates);
        const geonameData = await getGeoName(localization.country?.id || localization.country?.value, lang);

        return { trans, geoname: geonameData?.data }
    }, [lang, localization.country?.id, localization.country?.value, translation])

    const cancel = () => {
        setIsShowPortalModal(false);
        setIsShowConfirmModal && setIsShowConfirmModal(false);
    }


    return (
        <Portal fullWidth={mobile} fullHeight={mobile} closeHandler={() => { setIsShowPortalModal(false) }}>
            <Div styles={mobile ? styles.modalWrapperMobile : {}} data-uitest='672_uitest' onClick={() => setIsShowPortalModal(false)}>
                <Div data-uitest='673_uitest' onClick={(e) => {
                    e.stopPropagation()
                }} styles={mobile ? styles.modalBody : styles.modalWrapper}>
                    {
                        mobile ? <Div styles={styles.modalTopDecor}/> :
                            <Div
                                data-uitest='674_uitest' onClick={() => cancel()}
                                styles={styles.modalClose}
                            >
                                {svgSprite('cross', {
                                    style: styles.modalCloseSvg,
                                    fill: styles.variable.greyExtraDarkColor
                                })}
                            </Div>

                    }

                    <P styles={styles.modalTitle}>
                        <T customTranslates={trans} textName='SiteSettings-app-setting' defaultStr='Настройки сайта' page='widget' group='SiteSettings'/>
                    </P>
                    <Skeleton
                        elements={hideCountryAndCurrency ? [
                            'blockMD'
                        ] : [
                            'blockMD',
                            'empty',
                            'blockMD',
                            'empty',
                            'blockMD'
                        ]}
                        handler={loadData}
                        Component={RenderForm}
                        componentProps={{
                            setIsShowPortalModal, setIsShowConfirmModal, cancel, redirectToDomain, hideCountryAndCurrency
                        }}
                    />
                </Div>
            </Div>
        </Portal>
    );
}
export default Styles(RegionAndCurrencySwitcher, getStyles);