import React from 'react';
import T, { t } from '../../common/components/T';


export const getFormData = (
    currency, activeLangs, formData, translation, hideCountryAndCurrency
) => {
    if (hideCountryAndCurrency) {
        return [
            {
                attribute: 'language',
                group: 'main_info',
                disabled: activeLangs?.length > 1 ? false : true,
                options: activeLangs?.map((el) => ({
                    id: el.alias,
                    title: el.name
                })),
                title: { body: <T textName='SiteSettings-Language' defaultStr='Язык' page='widget' group='SiteSettings'/> },
                name: 'language',
                placeholder: t(
                    'SiteSettings-ChooseLanguage', 'Выберите язык', 'widget', translation, 'SiteSettings'
                ),
                value: formData['language'] || null,
                type: 'dropdown'
            }
        ]
    } else {
        return [
            {

                attribute: 'country',
                group: 'main_info',
                disabled: false,
                title: { body: <T textName='SiteSettings-Country' defaultStr='Страна' page='widget' group='SiteSettings'/> },
                maxMenuHeight: 240,
                name: 'country',
                placeholder: t(
                    'SiteSettings-ChooseCountry', 'Выберите страну', 'widget', translation, 'SiteSettings'
                ),
                type: 'location_widget',
                value: formData['country'] || null,
                preparedLoad: true
            },
            {
                attribute: 'language',
                group: 'main_info',
                disabled: activeLangs?.length > 1 ? false : true,
                options: activeLangs?.map((el) => ({
                    id: el.alias,
                    title: el.name
                })),
                title: { body: <T textName='SiteSettings-Language' defaultStr='Язык' page='widget' group='SiteSettings'/> },
                name: 'language',
                placeholder: t(
                    'SiteSettings-ChooseLanguage', 'Выберите язык', 'widget', translation, 'SiteSettings'
                ),
                value: formData['language'] || null,
                type: 'dropdown'
            },
            {
                attribute: 'currency',
                group: 'main_info',
                options: currency || [],
                title: { body: <T textName='SiteSettings-Currency' defaultStr='Валюта' page='widget' group='SiteSettings'/> },
                name: 'currency',
                placeholder: t(
                    'SiteSettings-ChooseCurrency', 'Выберите валюту', 'widget', translation, 'SiteSettings'
                ),
                type: 'dropdown',
                value: formData['currency'] || null,
                disabled: false
            }
        ]
    }
};