import React, {
    useContext, useEffect, useMemo, useState
} from 'react';
import { Div, zendoStorage } from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/region-and-currency-switcher';
import Styles from '../../common/decorators/Styles';
import SingleButton from '../form/form-control-buttons/SingleButton';
import T, { t } from '../../common/components/T';
import { LangContext } from '../../common/decorators/Language';
import UserContext from '../../common/utils/getContext';
import { getWidgetTranslates } from '../../common/utils/utils';
import { getGeoName } from '../../common/utils/memo';

const RegionAndCurrencySwitcherConfirm = ({
    styles, mode, setIsShowPortalModal, setIsShowConfirmModal, redirectToDomain
}) => {
    const { translation } = useContext(LangContext);

    const {
        localization,
        lang,
        setState
    } = useContext(UserContext);

    const [geoname, setGeoname] = useState({ country: localization?.country?.code });
    const [trans, setTranslates] = useState(null);
    useEffect(() => {
        getWidgetTranslates(lang, 'SiteSettings', translation, setTranslates);
    }, [lang, translation])

    useEffect(() => {
        if (localization.country?.id) {
            zendoStorage.set('localization', JSON.stringify(localization));
            getGeoName(localization.country?.id, lang)
                .then((res) => {
                    setGeoname(res.data);
                })
        }
    }, [lang, localization, localization?.country?.id]);

    const buttons = useMemo(() => [
        {
            type: 'cancel',
            buttonText: t('no', 'Нет', 'all', translation),
            clickFunc: () => {
                setIsShowConfirmModal(false);
                setIsShowPortalModal(true);
            }
        },
        {
            type: 'ok',
            buttonText: t('yes', 'Да', 'all', translation),
            clickFunc: () => {
                setIsShowConfirmModal(false);
                if (!!localization && typeof localization === 'object') {
                    setState('localization', localization)
                    redirectToDomain(localization)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [translation, setIsShowConfirmModal, setIsShowPortalModal, localization, setState]);


    const customFormButton = {
        cancel: { butWrapper: styles.cancelButton },
        ok: { butWrapper: styles.submitButton }
    };

    return (
        <Div styles={{ ...styles.dropDownWrapper, ...mode === 'left' ? styles.dropDownWrapperLeft : {} }}>

            <Div styles={styles.triangleDropdown}/>

            <Div styles={styles.dropDown}>

                <Div styles={styles.dropDownText}>
                    <T customTranslates={trans} textName='SiteSettings-dropDownText' defaultStr='Ваш регион ${country}?'
                        vars={{ country: geoname?.name }} page='widget' group='SiteSettings'/>

                    <Div styles={styles.dropDownBtnsWrapper}>
                        {
                            buttons.map((item, idx) =>
                                <SingleButton
                                    key={`button${idx}`}
                                    customStyles={styles}
                                    type={item.type}
                                    clickFunc={item.clickFunc}
                                    buttonText={item.buttonText}
                                    customButton={customFormButton[item.type]}
                                />)
                        }
                    </Div>

                </Div>
            </Div>
        </Div>
    );
}

export default Styles(RegionAndCurrencySwitcherConfirm, getStyles);