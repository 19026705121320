import { media } from '../../../common/utils/utils';
import officeStyles from './office/lang-switcher-styles';
import mainStyles from './main/lang-switcher-styles';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles,
    mainStyles,
    menuLangsWrapper: media(width, height, 'w', {
        768: {
            position: 'relative',
            'z-index': '99'
        },
        0: { display: 'none' }
    }),
    menuLangsBackdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'transparent',
        'z-index': '-2'

    },
    menuLangMargin: { 'margin-left': '0' },
    menuLangSwitcherDesktop: {
        width: '48px',
        'min-width': '48px',
        height: '48px',
        border: `1px solid ${variable.greyExtraDarkColorTransparent}`,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '12px',
        'cursor': 'pointer'
    },
    menuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        overflow: 'hidden'
    },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherInfo: { padding: '0 0 0 20px' },
    menuLangSwitcherTitle: {
        ...uikit.tiny,
        'line-height': '1.16',
        color: variable.greySuperDarkColor,
        margin: '0 0 2px'
    },
    menuLangSwitcherName: {
        ...uikit.small,
        'line-height': '1.14'
    },
    langSwitcher: media(width, height, 'w', {
        992: { width: '74px' },
        0: { width: '44px' }
    }),
    menuLangSwitcher: media(width, height, 'w', {
        0: {
            'min-height': '65px',
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`,
            padding: '16px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center'
        }
    }),
    menuLangSwitcherFlag: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherNameDesktop: {
        'text-transform': 'capitalize',
        ...uikit.small
    },
    modalLangListDesktop: {
        'overflow': 'hidden',
        'border-radius': '14px'
    },
    modalLangListArrow: {
        position: 'absolute',
        top: '-12px',
        height: '14px',
        right: '50%',
        width: '33px',
        transform: 'translateX(50%)',
        'z-index': '0',
        fill: variable.whiteColor
    },
    modalLangListArrowLeft: { right: '24px' },
    modalLangListWrap: {
        position: 'absolute',
        top: '100%',
        right: '50%',
        transform: 'translateX(50%)',
        'background': variable.whiteColor,
        'border-radius': '14px',
        'margin-top': '14px',
        'box-shadow': `0 0 10px ${variable.blackExtraTransparentColor}`
    },
    modalLangListWrapLeft: {
        right: '0',
        transform: 'none'
    },
    modalLangListItemFirst: { 'border-top': 'none' },
    modalLangListItemHover: { 'background': `${variable.greyExtraDarkTransparentColor}` },
    menuLangSwitcherListFlag: {
        'margin-right': '10px',
        background: variable.greyExtraLightColor,
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherInfoDesktop: {
        ...uikit.small,
        'white-space': 'pre'
    },
    modalLangListItemDesktop: {
        display: 'flex',
        'align-items': 'center',
        padding: '12px',
        'border-top': `1px solid ${variable.darkTransparentColor}`,
        'cursor': 'pointer',
        fill: variable.blueTotalGray
    },
    mobileMenuLangSwitcher: media(width, height, 'w', {
        0: {
            'min-height': '65px',
            width: '100%',
            'border-top-style': 'solid',
            'border-top-width': '1px',
            'border-top-color': variable.darkSuperTransparentColor,
            'border-bottom-style': 'solid',
            'border-bottom-width': '1px',
            'border-bottom-color': variable.darkSuperTransparentColor,
            padding: '16px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center'
        }
    }),
    mobileMenuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        overflow: 'hidden'
    },
    mobileMenuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    mobileMenuLangSwitcherInfo: {
        padding: '0 0 0 20px',
        'text-align': 'left'
    },
    mobileMenuLangSwitcherTitle: {
        ...uikit.tiny,
        'line-height': '1.16',
        color: variable.greySuperDarkColor,
        margin: '0 0 2px'
    },
    mobileMenuLangSwitcherName: {
        ...uikit.small,
        'line-height': '1.14'
    },
    loaderWrapper: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: 'rgba(0,0,0 ,0.5)',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'z-index': '999'
    },
    modalContent: media(width, height, 'w', {
        0: {
            width: '100%',
            height: '100%',
            display: 'flex',
            'justify-content': 'center',
            background: variable.blackTransparentColor,
            'flex-direction': 'row',
            'align-items': 'flex-end'
        }
    }),
    modalBody: media(width, height, 'w', {
        425: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        },
        0: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    modalTopDecor: {
        width: '32px',
        height: '4px',
        background: variable.greyExtraLightColor,
        'border-radius': '100px',
        margin: '0 0 20px'
    },
    modalBottomDecor: {
        width: '134px',
        height: '4px',
        margin: '21px 0 0'
    },
    modalTitle: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    modalLangListWrapper: {
        width: '100%',
        overflow: 'hidden'
    },
    modalLangList: { margin: '-3px 0 -1px' },
    modalLangListItem: {
        'border-bottom-style': 'solid',
        'border-bottom-width': '1px',
        'border-bottom-color': variable.darkExtraTransparentColor,
        padding: '16px 0',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
    },
    menuLangMargin: {},
    menuLangsWrapper: media(width, height, 'w', {
        768: {
            position: 'relative',
            'z-index': '99'
        },
        0: { display: 'none' }
    }),
    menuLangsBackdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'transparent',
        'z-index': '-2'

    },
    menuLangSwitcherDesktop: {
        width: '48px',
        'min-width': '48px',
        height: '48px',
        'border-right': `1px solid ${variable.greyExtraDarkColorTransparent}`,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'cursor': 'pointer'
    },
    menuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        overflow: 'hidden'
    },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherNameDesktop: {
        'text-transform': 'capitalize',
        ...uikit.small
    },
    modalTitle: {
        color: variable.darkColor,
        ...uikit.title,
        'text-align': 'center',
        'font-weight': '500',
        'max-width': '376px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'margin-bottom': '32px'
    },
    FormWeb: media(width, height, 'w', {
        768: { width: '100%' },
        0: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': 1
        }
    }),
    formGroupWrapper: {
        display: 'flex',
        'flex-direction': 'column',
        'flex-wrap': 'nowrap',
        'justify-content': 'flex-start',
        'align-items': 'stretch',
        'margin-bottom': '16px',
        'max-width': '100%',
        width: '100%',
        flex: 'none'
    },
    modalClose: {
        position: 'absolute',
        top: '24px',
        right: '30px',
        'cursor': 'pointer'
    },
    modalCloseSvg: {
        fill: variable.greyExtraDarkColor,
        width: '12px',
        height: '12px'
    },
    modalWrapper: {
        'min-width': '576px',
        'max-width': '100%',
        background: variable.whiteColor,
        'border-radius': '20px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        position: 'relative',
        display: 'flex',
        'flex-direction': 'column',
        padding: '32px'
    },
    dropDownBtnsWrapper: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'margin-top': '12px'
    },
    dropDownText: { 'min-width': 'max-content' },
    dropDownWrapper: {
        filter: `drop-shadow(${variable.blackTransparentColor} 0px 10px 100px)`,
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center',
        position: 'absolute',
        'z-index': '100',
        top: 'calc(100% + 6px)',
        left: '50%',
        transform: 'translateX(-50%)',

        'font-style': 'normal',
        'font-weight': '500',
        ...uikit.small,
        'line-height': '1.14',
        color: variable.darkColor,
        'text-align': 'center'
    },
    dropDown: {
        'background': variable.whiteColor,
        'border-radius': '14px',
        padding: '16px 30px',
        position: 'relative',
        top: '-5px'
    },
    triangleDropdown: {
        width: 0,
        height: 0,
        'border-left': '16.5px solid transparent',
        'border-right': '16.5px solid transparent',

        'border-bottom': `15px solid ${variable.whiteColor}`
    },
    wrapper: {
        padding: '12px',
        height: '48px',

        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'cursor': 'pointer',
        'border-left': 'none',

        'font-style': 'normal',
        'font-weight': 'normal',
        ...uikit.small,
        'line-height': '1.14'
    }
});
export default stylesheets;